import { ActionEvent, Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "source" ]
  static values = {
    doneLabel: String
  }
  static classes = [ "done" ]

  declare readonly sourceTarget: HTMLInputElement
  declare doneLabelValue: string
  declare readonly doneClass: string

  copy(evt: ActionEvent) {
    this.sourceTarget.select()
    navigator.clipboard.writeText(this.sourceTarget.value)
    const el = evt.currentTarget as Element
    el.classList.add(this.doneClass)
    el.innerHTML = this.doneLabelValue
  }
}
