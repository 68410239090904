import { ActionEvent, Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "radio", "block" ]
  static classes = [ "active", "nonActive" ]

  declare readonly radioTargets: HTMLInputElement[]
  declare readonly blockTargets: HTMLInputElement[]
  declare readonly activeClass: string
  declare readonly nonActiveClass: string

  connect(): void {
    this.radioTargets.forEach(input => input.addEventListener("change", this.check))
    this.check()
  }

  check = () => {
    const selected = this.radioTargets.find(input => input.checked)
    if (!selected) { return }

    this.blockTargets.forEach(block => {
      const wants = block.getAttribute("data-radio-tabs-sync") || block.id
      block.hidden = wants != selected.value
    })
  }
}
