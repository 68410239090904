import { ActionEvent, Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "radio", "input" ]

  declare readonly radioTargets: HTMLInputElement[]
  declare readonly inputTargets: HTMLInputElement[]

  connect(): void {
    this.radioTargets.forEach(input => input.addEventListener("change", this.check))
    this.check()
  }

  check = () => {
    const selected = this.radioTargets.find(input => input.checked)
    if (!selected) { return }

    this.inputTargets.forEach(input => {
      const wants = input.getAttribute("data-exclusive-inputs-sync") || input.name
      if (wants == selected.value) {
        input.disabled = false
      } else {
        input.disabled = true
        input.value = ""
        input.dispatchEvent(new Event("change"))
      }
    })
  }
}
