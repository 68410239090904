import { Controller } from "@hotwired/stimulus"

const CLASS_WHEN_CHECKED = "admin__userImport__user--checked"
const CLASS_WHEN_UNCHECKED = "admin__userImport__user--unchecked"

export default class extends Controller {
  static targets = [ "checkbox" ]

  declare readonly checkboxTarget: HTMLInputElement

  update() {
    if (this.checkboxTarget.checked) {
      this.element.classList.remove(CLASS_WHEN_UNCHECKED)
      this.element.classList.add(CLASS_WHEN_CHECKED)
    } else {
      this.element.classList.add(CLASS_WHEN_UNCHECKED)
      this.element.classList.remove(CLASS_WHEN_CHECKED)
    }
  }

  connect() {
    this.update()
  }
}
