import { Controller } from "@hotwired/stimulus"
import { some, last } from "lodash"

export default class FormController extends Controller {
  static CheckEveryMs = 1000

  static targets = [ "input", "submit" ]

  declare interval: NodeJS.Timer
  declare readonly inputTargets: HTMLInputElement[]
  declare readonly submitTarget: HTMLButtonElement

  connect() {
    this.inputTargets.forEach(el => el.addEventListener("change", this.check))
    this.check()
  }

  check = () => {
    const hasIncomplete = some(this.inputTargets, input => !input.value)
    if (hasIncomplete) {
      this.submitTarget.setAttribute("disabled", "disabled")
    } else {
      this.submitTarget.removeAttribute("disabled")
    }
  }
}

