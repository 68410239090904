import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "radio" ]

  declare readonly radioTarget: HTMLInputElement

  checkRadio() {
    this.radioTarget.checked = true
  }
}
