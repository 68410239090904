import {bb, line, ChartOptions} from "billboard.js"
import {merge} from "lodash"

const SELECTORS = [
  ".js-graph",
]

const ATTRIBUTES = {
  graph: "data-graph",
  config: "data-graph-config",
}

// see: https://naver.github.io/billboard.js/release/latest/doc/Options.html
const DEFAULT_OPTIONS: ChartOptions = {
  tooltip: {
    show: true,
  },
  legend: {
    show: false,
  },
}

// boot modules

line()


const applyBillboardJS = () => {
  SELECTORS.forEach((selector) => {
    const elements = document.querySelectorAll(selector)
    elements.forEach((element) => {
      // apply all elements which have ChoicesJS already applied
      if (element.getAttribute(ATTRIBUTES.graph) === "active") {
        return
      }
      element.setAttribute(ATTRIBUTES.graph, "active")
      const config = JSON.parse(element.getAttribute(ATTRIBUTES.config))
      // using lodash recursive merge operation here
      bb.generate(merge(DEFAULT_OPTIONS, {bindto: element}, config))
    })
  })
}

export default {
  start: () => {
    document.addEventListener("DOMContentLoaded", applyBillboardJS)
    document.addEventListener("turbolinks:load", applyBillboardJS)
  }
}
