import tippy from "tippy.js"
import "tippy.js/dist/tippy.css"

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { title: String }

  declare readonly titleValue: string

  connect() {
    const content = this.titleValue || this.element.getAttribute("title")
    tippy(this.element, { content })
    this.element.classList.add("admin__tippy")
  }
}
