import { ActionEvent, Controller } from "@hotwired/stimulus"
import QRCode from "qrcode"

export default class extends Controller {
  static targets = [ "source", "container" ]
  static classes = [ "done" ]

  declare readonly sourceTarget: HTMLInputElement
  declare readonly containerTarget: HTMLElement
  declare readonly doneClass: string

  show(evt: ActionEvent) {
    const url = this.sourceTarget.value
    this.containerTarget.classList.remove(this.doneClass)

    if (!url) { return }

    QRCode.toDataURL(url, {scale: 10}).then(url => {
      const img = document.createElement("img")
      img.src = url
      img.onclick = (evt) => {
        evt.stopPropagation()
      }
      this.containerTarget.replaceChildren(img)
      this.containerTarget.classList.add(this.doneClass)
    })
  }

  hide() {
    this.containerTarget.classList.remove(this.doneClass)
    this.containerTarget.replaceChildren()
  }
}
