import { Controller } from "@hotwired/stimulus"

interface DropEvent extends Event {
  dataTransfer?: DataTransfer
}

export default class extends Controller {
  static targets = [ "zone", "input" ]
  static classes = [ "highlight" ]

  declare readonly zoneTarget: HTMLElement
  declare readonly inputTarget: HTMLInputElement

  declare readonly highlightClass: string

  connect(): void {
    this.zoneTarget.addEventListener("drag", this.handleDefault)
    this.zoneTarget.addEventListener("dragstart", this.handleDefault)
    this.zoneTarget.addEventListener("dragend", this.handleDefault)
    this.zoneTarget.addEventListener("dragenter", this.handleEnter)
    this.zoneTarget.addEventListener("dragover", this.handleEnter)
    this.zoneTarget.addEventListener("dragleave", this.handleLeave)
    this.zoneTarget.addEventListener("drop", this.handleDrop)
  }

  handleDefault = (evt: Event) => {
    evt.preventDefault()
    evt.stopPropagation()
  }

  handleEnter = (evt: DropEvent) => {
    evt.preventDefault()
    evt.stopPropagation()
    if (this.inputTarget.disabled) { return }
    this.zoneTarget.classList.add(this.highlightClass)
  }

  handleLeave = (evt: DropEvent) => {
    evt.preventDefault()
    evt.stopPropagation()
    if (this.inputTarget.disabled) { return }
    this.zoneTarget.classList.remove(this.highlightClass)
  }

  handleDrop = (evt: DropEvent) => {
    evt.preventDefault()
    evt.stopPropagation()
    if (this.inputTarget.disabled) { return }
    this.zoneTarget.classList.remove(this.highlightClass)
    if (isAccepted(evt.dataTransfer, this.inputTarget)) {
      this.inputTarget.files = evt.dataTransfer.files
      this.inputTarget.dispatchEvent(new Event("change"))
    }
  }
}

function isAccepted(transfer: DataTransfer, input: HTMLInputElement) {
  if (!transfer) { return false }
  if (transfer.files.length !== 1) { return false }
  const file = transfer.files[0]
  const index = input.accept.split(",").findIndex(extension => file.name.endsWith(extension))
  return index !== -1
}
