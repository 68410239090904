import { ActionEvent, Controller } from "@hotwired/stimulus"

export default class FilePreviewController extends Controller {
  static CheckEveryMs = 200

  static targets = [ "input", "preview" ]

  static classes = [ "disabled" ]

  declare readonly inputTarget: HTMLInputElement
  declare readonly previewTarget: HTMLElement
  declare readonly disabledClass: string

  declare initialNameOrUrl: string
  declare interval: NodeJS.Timer

  connect(): void {
    switch(this.previewTarget.nodeName) {
      case "IMG":
        const img = this.previewTarget as HTMLImageElement
        this.initialNameOrUrl = img.src
        break
      case "SPAN":
        const span = this.previewTarget as HTMLSpanElement
        this.initialNameOrUrl = span.innerText
        break;
      default:
        console.warn("Unsupported target for file preview", this.previewTarget)
        break
    }
    this.previewTarget.addEventListener("click", this.triggerFilePicker)
    this.inputTarget.addEventListener("change", this.updatePreview)
    this.interval = setInterval(this.updateClasses, FilePreviewController.CheckEveryMs)
    this.updateClasses()
  }

  disconnect(): void {
    if(this.interval) clearInterval(this.interval)
    delete this.interval
  }

  triggerFilePicker = () => {
    this.inputTarget.click()
  }

  updatePreview = () => {
    const input = this.inputTarget
    switch(this.previewTarget.nodeName) {
      case "IMG":
        const img = this.previewTarget as HTMLImageElement
        if (input.files.length === 0) {
          img.src = this.initialNameOrUrl
        } else {
          const file = input.files[0]
          if (isImage(file)) {
            img.src = URL.createObjectURL(file)
          } else if (isVideo) {
            renderVideoPreview(file, img)
          } else {
            console.warn("Unsupported file type:", file.type)
          }
        }
        break
      case "SPAN":
        // render file name only
        const span = this.previewTarget as HTMLSpanElement
        if (input.files.length === 0) {
          span.innerText == this.initialNameOrUrl
        } else {
          span.innerText = input.files[0].name
        }
        break
      default:
        console.warn("Unsupported target for file preview", this.previewTarget)
        break
    }
  }

  updateClasses = () => {
    if(this.inputTarget.disabled) {
      this.previewTarget.classList.add(this.disabledClass)
    } else {
      this.previewTarget.classList.remove(this.disabledClass)
    }
  }
}

function isImage(file) {
  return [
    "image/apng",
    "image/bmp",
    "image/gif",
    "image/jpeg",
    "image/pjpeg",
    "image/png",
    "image/svg+xml",
    "image/tiff",
    "image/webp",
    "image/x-icon"
  ].includes(file.type);
}

function isVideo(file) {
  return [
    "image/mp4",
  ].includes(file.type);
}

function renderVideoPreview(file: File, target: HTMLImageElement, seekTo: number = 0.0) {
  return new Promise((resolve, reject) => {
    console.log("Render video preview for:", file, "into:", target)
    // load the file to a video player
    const videoPlayer = document.createElement("video")
    videoPlayer.setAttribute("src", URL.createObjectURL(file))
    videoPlayer.load()
    videoPlayer.addEventListener("error", ex => {
        reject(`Error when loading video file: ${ex}`);
    });
    // load metadata of the video to get video duration and dimensions
    videoPlayer.addEventListener("loadedmetadata", () => {
        // seek to user defined timestamp (in seconds) if possible
        if (videoPlayer.duration < seekTo) {
            reject("Video is too short");
            return;
        }
        // extract video thumbnail once seeking is complete
        videoPlayer.addEventListener("seeked", () => {
            console.log("Video is now paused at %ss.", seekTo);
            videoPlayer.pause()
            // define a canvas to have the same dimension as the video
            const canvas = document.createElement("canvas")
            canvas.width = videoPlayer.videoWidth
            canvas.height = videoPlayer.videoHeight
            // draw the video frame to canvas
            const ctx = canvas.getContext("2d")
            ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height)
            // return the canvas image as a blob
            ctx.canvas.toBlob(
              blob => {
                target.src = URL.createObjectURL(blob)
                resolve(blob)
              },
              "image/jpeg",
              0.75 // quality
            );
        });
        // delay seeking or else 'seeked' event won't fire on Safari
        setTimeout(() => {
          videoPlayer.currentTime = seekTo;
        }, 200);
    });
  });
}
