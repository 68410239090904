import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "checkbox" ]
  static classes = [ "checked", "unchecked" ]

  declare readonly checkboxTarget: HTMLInputElement
  declare readonly checkedClass: string
  declare readonly uncheckedClass: string

  connect(): void {
    this.checkboxTarget.addEventListener("change", this.check)
    this.check()
  }

  check = () => {
    if (this.checkboxTarget.checked) {
      this.element.classList.remove(this.uncheckedClass)
      this.element.classList.add(this.checkedClass)
    } else {
      this.element.classList.add(this.uncheckedClass)
      this.element.classList.remove(this.checkedClass)
    }
  }
}
