import { Controller } from "@hotwired/stimulus"
import { some, last } from "lodash"

export default class FormController extends Controller {
  static CheckEveryMs = 1000

  static targets = [ "entries", "addUserButton" ]
  static values = {
    resource: String
  }

  declare interval: NodeJS.Timer
  declare resourceValue: string
  declare readonly addUserButtonTarget: HTMLButtonElement
  declare readonly entriesTargets: HTMLElement[]

  connect() {
    // instead of attaching event listeners to each input, I opted to periodically
    // check the form for "completeness"
    this.interval = setInterval(this.tick, FormController.CheckEveryMs)
    this.tick()
    this.focusLastEntry()
  }

  disconnect() {
    if(this.interval) clearInterval(this.interval)
    delete this.interval
  }

  tick = () => {
    const hasIncompleteEntry = some(this.entriesTargets, (container) => {
      const fields = container.querySelectorAll<HTMLInputElement|HTMLSelectElement>(
        `input[type=text][name^=${this.resourceValue}], select[name^=${this.resourceValue}]`
      )
      return some(fields, field => field.value === "")
    })
    if (hasIncompleteEntry) {
      this.addUserButtonTarget.setAttribute("disabled", "disabled")
    } else {
      this.addUserButtonTarget.removeAttribute("disabled")
    }
  }

  focusLastEntry = () => {
    const lastEntry = last(this.entriesTargets)
    lastEntry?.querySelector<HTMLInputElement>("input[type=text]").focus()
  }
}
